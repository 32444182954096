
import { metaDataMixin } from '~/mixins'

export default {
  mixins: [
    metaDataMixin,
  ],
  async asyncData ({ $api, $config, store, route, params: { slug }, error, redirect }) {
    // Render rubriek of Ter Apel on another route
    if (slug === 'gezichten-van-ter-apel') {
      redirect('/gezichten-van-ter-apel')
      return
    }

    // Get the story from any of these 4 environments
    const [
      { data: { items: eoStory = [] } = {} },
      { data: { items: visieStory = [] } = {} },
      { data: { items: evaStory = [] } = {} },
      { data: { items: lazarusStory = [] } = {} },
  ] = await Promise.all([
      $api.stories.getOneBySlug(slug, {
        domain: $config.preprEnvironmentDomainEo,
      }),
      $api.stories.getOneBySlug(slug, {
        domain: $config.preprEnvironmentDomainVisie,
      }),
      $api.stories.getOneBySlug(slug, {
        domain: $config.preprEnvironmentDomainEva,
      }),
      $api.stories.getOneBySlug(slug, {
        domain: $config.preprEnvironmentDomainLazarus,
      }),
    ])

    const story = eoStory[0] || visieStory[0] || evaStory[0] || lazarusStory[0]

    if (!story) {
      error({
        statusCode: 404,
        message: '(404) Rubriek niet gevonden',
        context: `Couldn't find story: ${slug}.`,
        severity: 'warning',
      })
      return false
    }

    if (story.environmentId == $config.preprEnvironmentIdEva) {
      // Set analytics project to Eva
      store.dispatch('pianoAnalytics/setProject', $config.trackingFieldEva)
    } else if (story.environmentId == $config.preprEnvironmentIdVisie) {
      // Set analytics project to Visie
      store.dispatch('pianoAnalytics/setProject', $config.trackingFieldVisie)
    } else {
      store.dispatch('pianoAnalytics/setProject', $config.trackingFieldHome)
    }

    // Set breadcrumbs
    await store.dispatch('breadcrumbs/set', {
      path: route.path,
      items: [
        {
          to: '/',
          label: 'Home',
        },
        {
          label: story.name,
        },
      ],
    })

    return {
      ...story,
      storyId: story.id,
      title: story.name,
      seo: {
        title: story.name,
        description: `Alle artikelen van '${story.name}' vind je in dit overzicht.`,
      },
    }
  },
  data () {
    return {
      storyId: null,
      articles: [],
      hasMore: false,
      seo: {
        title: null,
        description: null,
      },
      social: {
        image: null,
      },
    }
  },
  async fetch () {
    const { data: { items: articles = [], meta: { total = 0 } } } = await this.$api.articles.getAll({
      offset: this.articles.length,
      limit: 12,
      story: this.storyId,
    })
    this.articles.push(...articles)
    this.hasMore = total > this.articles.length
  },
}
